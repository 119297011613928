import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import 'aos/dist/aos.css';
import Layout from 'components/layout';
import Head from 'components/head';
import Box from 'components/box';
import PageBlocks from 'components/pageBlocks';
import { Section, MainTitle } from '../index.css';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { didMount: false };
  }

  componentDidMount() {
    const AOS = require('aos');
    this.aos = AOS;
    this.aos.init();
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    const fields = JSON.parse(this.props.data.buroBorkPage.field);

    return (
      <Layout locale={fields.locale}>
        <Head pageTitle={fields.title} locale={fields.locale} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignItems: 'stretch',
            width: '100%',
            position: 'relative',
          }}
        >
          <Section>
            <div className="content">
              <MainTitle
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="500"
              >
                <h1>Een onderscheidende website</h1>
                <p>Die maken wij samen!</p>
              </MainTitle>
              <div className="blocks">
                <div
                  className="block"
                  data-aos="fade-left"
                  data-aos-delay="300"
                  data-aos-duration="500"
                >
                  Websites
                </div>
                <div
                  className="block"
                  data-aos="fade-left"
                  data-aos-delay="600"
                  data-aos-duration="500"
                >
                  Software
                </div>
                {/* <div
                  className="block"
                  data-aos="fade-left"
                  data-aos-delay="900"
                  data-aos-duration="500"
                >
                  SEO
                </div> */}
                <div
                  className="block"
                  data-aos="fade-left"
                  data-aos-delay="1200"
                  data-aos-duration="500"
                >
                  Hosting
                </div>
              </div>
            </div>
          </Section>
          <Section>
            <div className="content">
              <div className="content-text">
                <h2>Websites</h2>
                <p>
                  De eerste stap van het maken van een website is nadenken over
                  een ontwerp en kijken naar de wensen. De ontwerpfase van een
                  website is dan ook de belangrijkste fase, we beginnen dan ook
                  met een leeg scherm en gaan daar de wensen van de klant in
                  naar voren brengen. Wel nemen we hierin onze expertise op het
                  gebied van websites mee, zoals waar een bezoeker naar kijkt,
                  wat verwacht een bezoeker en hoe trek je veel bezoekers.
                  Vervolgens maken we ontwerp dat samen met de klant wordt
                  besproken zodat laatste aanpassingen nog gedaan kunnen worden
                  voordat we beginnen met bouwen van de website.
                </p>
              </div>
              <div className="big-text websites">
                Web
                <br />
                sites
              </div>
            </div>
          </Section>
          <Section>
            <div className="content">
              <div style={{ width: '10px' }} />
              <div className="content-text">
                <h2>Software</h2>
                <p>
                  Een passende applicatie voor jouw vraag stuk? Ik maak maatwerk
                  applicaties om jouw processen te vermakkelen of te verbeteren.
                </p>
              </div>
              <div className="big-text webapps">
                Soft
                <br />
                ware
              </div>
            </div>
          </Section>
          <Section>
            <div className="content">
              <div style={{ width: '10px' }} />
              <div className="content-text">
                <h2>Hosting</h2>
                <p>
                  De hosting van websites verzorgen wij ook zelf. Dit omdat we
                  uit ons streven leven om een snelle website te maken. Om een
                  snelle website te krijgen moet je ook op server niveau veel
                  dingen regelen en dat hebben we graag zelf in handen. Zowel
                  email als website/webapp hosting kan bij ons gerealiseerd
                  worden.
                </p>
              </div>
              <div className="content-text">
                <h3>Hosting Prijzen</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>Domeinnaam .nl</td>
                      <td>€ 7.50 p/j</td>
                    </tr>
                    <tr>
                      <td>Domeinnaam .com</td>
                      <td>€ 12.50 p/j</td>
                    </tr>
                    <tr>
                      <td>Domeinnaam .de</td>
                      <td>€ 10.50 p/j</td>
                    </tr>
                    <tr>
                      <td>Domeinnaam .eu</td>
                      <td>€ 19.50 p/j</td>
                    </tr>
                    <tr>
                      <td colSpan="2"> </td>
                    </tr>
                    <tr>
                      <td>E-mail hosting</td>
                      <td>€ 2,50 p/m</td>
                    </tr>
                    <tr>
                      <td>CMS hosting</td>
                      <td>€ 10,00 p/m </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="big-text hosting">Hosting</div>
            </div>
          </Section>
        </div>
        {fields.page_rows.map((row, index) => {
          let classes = 'page_row';
          if (row.options && row.options.classes) {
            classes = classNames(classes, Object.values(row.options.classes));
          }
          return (
            <Box key={index} className={classes}>
              <PageBlocks blocks={row.page_blocks} />
            </Box>
          );
        })}
      </Layout>
    );
  }
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query BuroBorkIndex {
    buroBorkPage(slug: { eq: "/" }) {
      id
      field
    }
  }
`;

export default Index;
