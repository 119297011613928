import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Section = styled.div`
  height: 100vh;
  display: flex;
  width: 100%;
  position: relative;
  ${MEDIA.TABLET`
         min-height: 100vh;
  height: auto;
  `};
  .content {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    overflow: hidden;
    h3 {
      font-family: 'Muli';
      font-size: 1.8rem;
      font-weight: 700;
      padding: 0 0 8px;
    }
    table,
    tbody {
      width: 100%;
      tr {
        width: 100%;
        td {
          padding: 8px 16px 8px 0;
        }
      }
    }
    ${MEDIA.TABLET`
    /* font-size: 64px; */
           justify-content: center;
    flex-direction: column;
  `};
  }
  h2 {
    font-family: 'Muli';
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    padding: 0 0 16px 0;
  }
  p {
    max-width: 60ch;
    font-size: 18px;
    line-height: 1.2;
  }
  .big-text {
    font-family: 'Muli';
    color: #30467d;
    font-weight: 900;
    position: absolute;
    height: 100%;
    vertical-align: middle;
    ${MEDIA.TABLET`
    /* font-size: 64px; */
    position: relative;
    color: rgba(48,70,125, 0.5)
  `};
    &.websites {
      right: -20px;
      font-size: 300px;
      line-height: 380px;
      text-align: right;
      ${MEDIA.TABLET`
    font-size: 150px;
    line-height: 1;
    padding: 0 0 50px;
  `};
    }
    &.webapps {
      left: -20px;
      font-size: 300px;
      line-height: 380px;
      text-align: left;
      ${MEDIA.TABLET`
    font-size: 120px;
    line-height: 1;
    padding: 0 0 50px;
  `};
    }
    &.hosting {
      font-size: 200px;
      top: 0;
      left: 65px;
      transform: rotate(270deg);
      ${MEDIA.TABLET`
      left: 0px;
      transform: rotate(0deg);
    font-size: 100px;
  `};
    }
  }
  .blocks {
    padding: 32px;
    ${MEDIA.TABLET`
           padding: 16px 32px;
  `};
    .block {
      font-family: 'Muli';
      text-align: center;
      padding: 40px 90px;
      margin: 20px 0;
      box-shadow: 0px 5px 20px rgba(48, 70, 125, 0.1);
      font-size: 30px;
      font-weight: 600;
      background: #fff;
      ${MEDIA.TABLET`
           padding: 16px 32px;
  `};
    }
  }
`;

export const MainTitle = styled.div`
  /* height: 100vh; */

  h1,
  p {
    color: #30467d;
  }
  h1 {
    line-height: 1.2;
    font-size: 70px;
    font-weight: 900;
    max-width: 20ch;
    position: relative;
    ${MEDIA.TABLET`
    margin: 64px 0 0;
           font-size: 34px;
  `};
    &:before {
      position: absolute;
      content: '';
      height: 4px;
      width: 120px;
      background: #33e11a;
      left: -125px;
      top: 40px;
    }
  }
  p {
    ${MEDIA.TABLET`
           font-size: 24px;
  `};
    font-size: 50px;
    font-weight: 500;
    line-height: 1.4;
  }
`;
